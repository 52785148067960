.TicketItem {
  position:relative;
  margin-bottom:15px;
  /*ffcb00*/
  overflow:hidden;
}

.TicketItem .mainDetails{
  position:relative;
  height:35px;
  background-color:#dadada;
}

.TicketItem .mainDetails .script{
  position:relative;
  float:left;
  vertical-align: top;
  width:15px;
  height:100%;
  margin-right:10px;
}
.TicketItem .mainDetails .nameDisplayer{
  float:left;
  text-align:left;
  color:#000;
  height:35px;
  width:calc(100% - 100px);
}
.TicketItem .mainDetails .nameDisplayer span{
  line-height:35px;
  text-transform: capitalize;
}

.TicketItem .mainDetails .size{
  text-transform: capitalize;
}

.TicketItem .newPrice .newPriceIndicator{
  position:absolute;
  top:0px;
  left:0px;
  height:100%;
  width:auto;
}

.TicketItem .newPrice{
  position:absolute;
  top:0px;
  right:0px;
  height:35px;
  width:75px;
  padding: 5px 8px 5px 8px;
}

.TicketItem .newPrice span{
  position:absolute;
  right:2.5px;
  z-index:10
}


.TicketItem .opPanel{
  position:relative;
  width:100%;
  height:35px;
}

.TicketItem .opPanel .price{
  background-color:black;
  color:white;
  height:35px;
  max-width: 110px;
  float:left;
  text-align:right;
  padding:5px 8px 5px 8px;
}

.TicketItem .opPanel .leftSide{
  float:left;
  width:115px;
}

.TicketItem .opPanel .times,
.TicketItem .opPanel .units{
  height:35px;
  display:inline-block;
  padding:5px 0px 5px 3px;
  margin-right:5px;
  vertical-align: middle;
}
.TicketItem .opPanel .units{
  width:25px;
}
.TicketItem .opPanel .price span,
.TicketItem  .opPanel .times span,
.TicketItem .opPanel .units span,
.TicketItem .opPanel .newPrice span{
  font-size:1.15rem;
  color:black;
}
.TicketItem .opPanel .price span{
  color:white;
}

.TicketItem .opPanel .roundedButton{
  width:30px;
  height:auto;
  vertical-align: top;
  margin:2.5px 5px 2.5px 0px;
  vertical-align: top;
  float:left;
}

.TicketItem .opPanel .roundedButton.bin{
  vertical-align: top;
  position:absolute;
  right:68px;
}

.TicketItem .opPanel .roundedButton.customButton{
  vertical-align: top;
  position:absolute;
  right:102px;
}

.TicketItem .opPanel .orderButton{
  position:absolute;
  right:5px;
  float:left;
  vertical-align: top;
  width:65px;
  height:28px;
  margin: 4px 0px 0px 5px;
}

.TicketItem .opPanel .orderButton span{
  color:white;
  font-size:0.85rem;
}

.TicketItem .extrasSummary{
  position:relative;
  margin-top:5px;
  width:100%;
  height:35px;
}



.TicketItem .extrasSummary .dataSpace{
  position:relative;
  height:35px;
  width:calc(100% - 75px);
  background-color:black;
  overflow:hidden;
}
.TicketItem .extrasSummary .dataSpace .yellowArrow{
  position:absolute;
  top:-3px;
  left:20px;
  width:21px;
  height:27px;
}
.TicketItem .extrasSummary .dataSpace .yellowArrow img{
  width:auto;
  height:100%;
}
.TicketItem .extrasSummary .dataSpace .plusSymbol{
  position:absolute;
  top:50%;
  left: 60px;
  width:13px;
  height:13px;
  transform: translate(0px, -65%);
}
.TicketItem .extrasSummary .dataSpace .plusSymbol img{
  width:auto;
  height:13px;
}
.TicketItem .extrasSummary .dataSpace p{
  padding-left:85px;
}
.TicketItem .extrasSummary .dataSpace p,
.TicketItem .extrasSummary .dataSpace span{
  font-size:1.15rem;
  line-height:35px;
  color:white;
}

.TicketItem .noActions{
  position:relative;
  float:right;
  width:calc(100% - 120px);
  height:35px;
}

.TicketItem .noActions .line{
  position:absolute;
  top:50%;
  left:0%;
  width:100%;
  height:1px;
  background-color:#ebebeb;
  transform:translate(0%,-50%);
}
